@import "../../style/bootstrapImports.scss";

.post-comment {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
        padding-left: map-get($spacers, 4);
        padding-right: map-get($spacers, 4);
    }

    align-items: flex-start;

    .comment-buttons {
        font-size: $font-size-sm;
    }
}

.post-comment.border-left {
    padding-left: calc(map-get($spacers, 3) - 1px);

    @include media-breakpoint-up(md) {
        padding-left: calc(map-get($spacers, 4) - 1px);
    }
}

@import "../../style/bootstrapImports.scss";

.search-box {
    position: relative;

    a,
    a:hover {
        color: inherit !important;
    }

    input {
        max-width: 250px;
        border: 0;
        outline: 0;
    }
}

.results-overlay {
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    position: fixed;
}

.search-pill {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
}

.resultsContainer {
    padding-top: 10px;
    z-index: 2;
}

.results {
    max-height: 500px;
    overflow-y: scroll;
    top: 50%;

    &::-webkit-scrollbar {
        width: 18px;
    }

    &::-webkit-scrollbar-track {
        border: 7px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: $gray-200;
        border-radius: 9999px;
    }

    &::-webkit-scrollbar-thumb {
        border: 7px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: $primary;
    }
}

.search-status-icon {
    margin-right: 1rem;
    width: 0.75rem;
    text-align: center;
}

@import "./style/bootstrapImports.scss";

.footer {
    background-color: $gray-200;
    margin-top: 2rem;

    .container {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    .summary {
        max-width: 300px;
    }

    a {
        color: inherit;
    }

    blockquote > p {
        font-size: 0.95rem;
        line-height: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .social-links > a {
        font-size: 1.25rem;
        margin-right: 0.75rem;
    }
}

@import "../../../style/bootstrapImports.scss";

.background {
    background-position: center;
    background-size: cover;
}

.card {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: map-get($spacers, 3);
}

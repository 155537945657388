.small {
    width: 2rem;
}

.medium {
    width: 2.7rem;
}

.large {
    width: 7rem;
}

.navbar-tm {
    font-size: 0.9375rem;

    .scroller {
        display: none;
    }

    .epochs {
        overflow-x: scroll;
    }
}

// TODO @include media-breakpoint-between(md, xl) does not work
@media (min-width: 768px) and (max-width: 1199.98px) {
    .navbar-tm {
        .scroller {
            display: initial;
        }
    }
}

@import "../../../style/bootstrapImports.scss";

.post-img-thumbnail {
    position: relative;
    background-color: #000;

    .imgWide {
        width: 100%;
    }

    .imgNarrow {
        max-height: 600px;

        @include media-breakpoint-down(sm) {
            max-height: unset;
            width: 100%;
        }
    }

    .more {
        position: absolute;
        width: 100%;
        bottom: 1rem;
        color: #fff;
    }

    .moreBtn {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 0.3rem 0.6rem;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
